import LoadingPage from 'components/LoadingPage';
import MDBox from 'components/MDBox';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import routes from 'routes';
import { useStore } from 'store';

const Landing = () => {
  const router = useRouter();
  const {
    AuthStore: { Permissions }
  } = useStore();

  const defaultPage = useMemo(() => routes(Permissions).filter((r) => r.can), [Permissions]);

  useEffect(() => {
    if (defaultPage.length === 0) return;
    router.replace(defaultPage[0].route);
  }, [defaultPage, router]);

  return (
    <MDBox minHeight="60svh">
      <LoadingPage />
    </MDBox>
  );
};

export default observer(Landing);
